import React from 'react';
import logo from './logo.svg';
import { AppBar, Toolbar, useScrollTrigger, Typography, Slide, Button, Divider } from '@material-ui/core';
import * as Scroll from 'react-scroll';
import BackgroundSlider from './backgroundSlider';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Rsvp from './Rsvp';
import CoupleTimeline from './CoupleTimeline';
import About from './About';
import Covid from './Covid';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  mainTitle: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  toolbar: {
    margin: "auto"
  },
  rsvpButton: {
    marginTop: "1rem",
    color: "white",
    border: "1px solid white",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.25)",
      color: "white",
      border: "1px solid white",
      //color: theme.palette.primary.main,
      //outline: theme.palette.primary.main,
    },
  },
}));

const images = [
  "image_1.jpg",
  "image_2.jpg",
  "image_3.jpg",
]



function ShowOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({threshold: 100});
  //const trigger = false;

  return (
    <Slide appear={false} direction="down" in={trigger}>
      {children}
    </Slide>
  );
}

function App() {
  const classes = useStyles();
  let history = useHistory();

  const handleRegistryClick = () => {
    history.push('/registry');
  };

  return (
      <div className={classes.root}>
        {/*
        <ShowOnScroll>
          <AppBar>
            <Toolbar className={classes.toolbar}>
              <Button activeClass="active" to="wedding" spy={true} smooth={true} duration={500} color="inherit">The Wedding</Button>
              <Button activeClass="active" to="story" spy={true} smooth={true} duration={500} color="inherit">Our Story</Button>
              <Button activeClass="active" className="btn" type="submit" value="Test 2" to="story" spy={true} smooth={true} offset={50} duration={500} >
                Test 2
              </Button>
              <Button color="inherit">Registry</Button>
            </Toolbar>
          </AppBar>
        </ShowOnScroll>
        */}
        <BackgroundSlider
          images={images}
          duration={3.5} transition={2} />
        
        <header className={classes.mainTitle}>
          <Typography variant="subtitle2">Helena &amp; Michael</Typography>
          <Typography variant="h5">Saturday, November 28, 2020</Typography>
          <Typography variant="body1">Eustis Estate    |    Milton, MA</Typography>
          <Rsvp variant="outlined" color="primary" style={classes.rsvpButton}/>
        </header>
        <body>
          <div name="covid">
            <Covid />
          </div>
          <div name="wedding">
            <About />
          </div>
          <div name="story">
            <CoupleTimeline />
          </div>
          <Divider light={true} variant="middle" />
          
          
        </body>
      </div>
  );
}

export default App;
