export default [
    {
        "date_group": "November 2011",
        "date": new Date("11/11/2011"),
        "location": "London",
        "icon": "School",
        "type": "text",
        "content": "Helena and Michael met in the common area of their dorm, Bankside Hall. Michael asked Helena if she was free that afternoon, because he liked to hop on the Tube and get off at a random stop to explore.",
    },
    {
        "date_group": "January 2012",
        "date":new Date ("01/23/2012"),
        "location": "Madrid",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "IMG_3964.jpg",
        "orientation": "landscape"
    },
    {
        "date_group": "March 2012",
        "date":new Date ("03/28/2012"),
        "location": "Venice",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "IMG_6604.jpg",
        "orientation": "landscape"
    },
    {
        "date_group": "October 2014",
        "date":new Date ("10/24/2014"),
        "location": "Hangzhou",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "IMG_20141024_111545.jpg",
        "orientation": "landscape"
    },
    {
        "date_group": "May 2015",
        "location": "Dominican Republic",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "IMG_20150510_113529.jpg",
        "orientation": "portrait"
    },
    {
        "date_group": "September 2015",
        "location": "Maryland",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "IMG_20150926_232746.jpg",
        "orientation": "landscape"
    },
    {
        "date_group": "February 2016",
        "location": "New York",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "IMG_20160220_133842.jpg",
        "orientation": "landscape"
    },
    {
        "date_group": "April 2016",
        "date":new Date ("04/30/2016"),
        "location": "New Orleans",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "IMG_20160430_130914.jpg",
        "orientation": "landscape"
    },
    {
        "date_group": "May 2016",
        "date":new Date ("05/12/2016"),
        "location": "Belize",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "IMG_20160512_161116.jpg",
        "orientation": "landscape"
    },
    {
        "date_group": "July 2016",
        "date":new Date ("07/21/2016"),
        "location": "Malibu",
        "icon": "FlightTakeOff",
        "type": "video",
        "content": "VID_20160721_194841.mp4",
        "orientation": "landscape"
    },
    {
        "date_group": "March 2017",
        "date":new Date ("3/12/2017"),
        "location": "London",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "IMG_20170312_164441.jpg",
        "orientation": "landscape"
    },
    {
        "date_group": "March 2017",
        "date":new Date ("3/9/2017"),
        "location": "London",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "IMG_20170309_150838.jpg",
        "orientation": "landscape"
    },
    {
        "date_group": "November 2016",
        "date": new Date("11/11/2016"),
        "location": "New York",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "IMG_20161111_213637.jpg",
        "orientation": "landscape"
    },
    {
        "date_group": "March 2016",
        "date": new Date("3/17/2016"),
        "location": "Washington, DC",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "Burst_stack_97896129194064_1.jpg",
        "orientation": "portrait"
    },
    {
        "date_group": "July 2016",
        "date": new Date("7/22/2016"),
        "location": "Kennebunk, ME",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "IMG_20160702_125332.jpg",
        "orientation": "landscape"
    },
    {
        "date_group": "May 2017",
        "date": new Date("5/2/2017"),
        "location": "Sydney",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "IMG_9061.jpg",
        "orientation": "landscape"
    },
    {
        "date_group": "May 2017",
        "date": new Date("5/17/2017"),
        "location": "Chiang Rai",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "IMG_20170517_155421-ANIMATION.gif",
        "orientation": "landscape"
    },
      {
        "content": "Michael proposed to Helena at the Tate Modern, where they went on their first date.",
        "date": new Date("11/4/2019"),
        "date_group": "November 2019",
        "location": "London",
        "type": "text",
      },
      {
        "content": "G0013077.jpg",
        "date": new Date("5/21/2017"),
        "date_group": "May 2017",
        "location": "Thailand",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "GOPR3542.jpg",
        "date": new Date("6/21/2017"),
        "date_group": "June 2017",
        "location": "Indonesia",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "vietnam.jpg",
        "date": new Date("5/27/2017"),
        "date_group": "May 2017",
        "location": "Vietnam",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20180721_163648.jpg",
        "date": new Date("7/21/2018"),
        "date_group": "July 2018",
        "location": "California",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20180905_130315.jpg",
        "date": new Date("9/5/2018"),
        "date_group": "September 2018",
        "location": "Shanghai",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20180908_084127.jpg",
        "date": new Date("9/8/2018"),
        "date_group": "September 2018",
        "location": "Huangshan",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20180911_122634.jpg",
        "date": new Date("9/11/2018"),
        "date_group": "September 2018",
        "location": "Zhangjiajie",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20180914_143403.jpg",
        "date": new Date("9/14/2018"),
        "date_group": "September 2018",
        "location": "Chengdu",
        "type": "photo",
        "orientation": "portrait"
      },
      {
        "content": "IMG_20181124_142832.jpg",
        "date": new Date("11/24/2018"),
        "date_group": "November 2018",
        "location": "Joshua Tree",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20190112_170057.jpg",
        "date": new Date("1/12/2019"),
        "date_group": "January 2019",
        "location": "Santa Monica",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20190328_184501.jpg",
        "date": new Date("3/28/2019"),
        "date_group": "March 2019",
        "location": "Belize",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20190527_135340.jpg",
        "date": new Date("5/27/2019"),
        "date_group": "May 2019",
        "location": "Mojave, CA",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20190614_144609.jpg",
        "date": new Date("6/14/2019"),
        "date_group": "June 2019",
        "location": "Los Angeles",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20190630_134725.jpg",
        "date": new Date("6/30/2019"),
        "date_group": "June 2019",
        "location": "Santa Barbara",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20191026_195225-ANIMATION.gif",
        "date": new Date("10/26/2019"),
        "date_group": "October 2019",
        "location": "San Francisco",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20191111_142722.jpg",
        "date": new Date("11/11/2019"),
        "date_group": "November 2019",
        "location": "London",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20191112_100907.jpg",
        "date": new Date("11/12/2019"),
        "date_group": "November 2019",
        "location": "London",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20191113_143417.jpg",
        "date": new Date("11/13/2019"),
        "date_group": "November 2019",
        "location": "Paris",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20191113_182712.jpg",
        "date": new Date("11/13/2019"),
        "date_group": "November 2019",
        "location": "Paris",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20191114_132804.jpg",
        "date": new Date("11/14/2019"),
        "date_group": "November 2019",
        "location": "London",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_20191114_140845.jpg",
        "date": new Date("11/14/2019"),
        "date_group": "November 2019",
        "location": "London",
        "type": "photo",
        "orientation": "portrait"
      },
      {
        "content": "IMG_2231.jpg",
        "date": new Date("5/28/2017"),
        "date_group": "May 2017",
        "location": "Vietnam",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_3716.jpg",
        "date": new Date("6/9/2017"),
        "date_group": "June 2017",
        "location": "Cambodia",
        "type": "photo",
        "orientation": "portrait"
      },
      {
        "content": "IMG_5408.jpg",
        "date": new Date("7/24/2017"),
        "date_group": "July 2017",
        "location": "Arches, UT",
        "type": "photo",
        "orientation": "landscape"
      },
      {
        "content": "IMG_5661.jpg",
        "date": new Date("7/24/2017"),
        "date_group": "July 2017",
        "location": "Arches, UT",
        "type": "photo",
        "orientation": "landsdcape"
      },
      {
        "content": "IMG_9240.mov",
        "date": new Date("4/15/2018"),
        "date_group": "April 2018",
        "location": "Coachella",
        "type": "video",
        "orientation": "portrait"
      }
    /*
    {
        "date_group": "MONTH YYYY",
        "location": "CITY",
        "icon": "FlightTakeOff",
        "type": "photo",
        "content": "LINKHERE",
        "orientation": "landscape"
    }
    */
]