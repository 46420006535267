import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Grid, Button, Divider } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Rsvp from "./Rsvp";

import SchoolIcon from "@material-ui/icons/School";
import FlightTakeOffIcon from "@material-ui/icons/FlightTakeoff";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "2rem",
  },
  divider: {
    width: "30%",
    margin: "auto",
    marginTop: "24px",
    marginBottom: "24px",
  },
  after: {
    marginBottom: "100px",
  },
  afterMobile: {
    marginBottom: "25px",
  },
  left: {
    textAlign: "left",
    maxWidth: "750px",
    margin: "auto",
  },
}));

export default function Covid() {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Grid container spacing={3} className={!isMobile && classes.main}>
        <Grid item xs={12} className={isMobile && classes.mainMobile}>
          <Typography className={classes.title}>
            COVID-19 Announcements
          </Typography>
          <Typography variant="body1">
            A message to guests regarding COVID safety standards
          </Typography>
          <Divider variant="middle" className={classes.divider} />
          <Grid container className={classes.left}>
            <Grid item xs={12}>
              <Typography variant="body1">
                In adhering to the local, state and federal directives for COVID
                safety, our indoor wedding event has been limited to 25 persons.
                This capacity allowance is subject to additional conditions and
                limitations further detailed below. Should the state of
                Massachusetts declare any new minimums for gatherings at a later
                time, we will require our guests to adhere to the new minimums
                and post these announcements here.
              </Typography>
              <br />
              <Typography variant="body1">
                For now, we respectfully ask all guests to please seek COVID
                testing (lab testing or rapid) and stay home (if / when
                appropriate) during the days leading up to the event. We are
                happy to help you find testing locations should you require it.
                To be considerate of all other guests, please{" "}
                <u>do not attend</u> if your test does not produce a negative
                result.
              </Typography>
              <br />
              <Typography variant="body1">
                Further, it is expected that all guests wear face masks when not
                eating, and maintain social distancing. We will work to make
                seating arrangements that accommodate social distancing
                practices.
              </Typography>
              <br />
              <Typography variant="body1">
                Thank you all for your understanding and cooperation in helping
                us celebrate this important day in a safe way! Should you have
                any questions or concerns, please do not hesitate to contact us.
              </Typography>
              <br />
            </Grid>
          </Grid>
          <Typography variant="h4">Helena &amp; Michael</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          className={isMobile ? classes.afterMobile : classes.after}
        >
          <Divider variant="middle" />
        </Grid>
      </Grid>
    </>
  );
}
