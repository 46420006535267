import React from "react";
import { useScrollTrigger, Slide } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Main from "./Main";
import Guests from "./Guests";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  mainTitle: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  toolbar: {
    margin: "auto",
  },
  rsvpButton: {
    marginTop: "1rem",
    color: "white",
    border: "1px solid white",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.25)",
      color: "white",
      border: "1px solid white",
      //color: theme.palette.primary.main,
      //outline: theme.palette.primary.main,
    },
  },
}));


function App() {
  const classes = useStyles();

  return (
    <Router>
      <a style={{position: "fixed", left: 10, top: 10}} href="https://www.myregistry.com/giftlist/helenaplusmichael" target="_blank" rel="noopener noreferrer"><img src="https://www.myregistry.com/Members/ShareRegistry/Images/together.jpg" alt="Wedding Registry"></img></a>
        
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/guests" component={Guests} />
        <Route path="/*" component={Main} />
      </Switch>
    </Router>
  );
}

export default App;
