import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
//import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


const breakpoints = createBreakpoints({})

const theme = createMuiTheme({
  overrides: {
    MuiTimelineItem: {
      missingOppositeContent: {
        '&::before': {
          [breakpoints.up("sm")]: {
            flex: 1,
          },
          [breakpoints.down("sm")]: {
            flex: 0,
          },
        }
      }
    }
  },
  palette: {
    primary: {
      main: "#822f3e",
      light: "#b55c68",
      dark: "#510018",
    },
    secondary: {
      main: "#ffa96f",
      light: "#ffdb9e",
      dark: "#c87a42",
    },
  },
  typography: {
    fontFamily: [
      '"Nanum Gothic"',
    ].join(','),
    subtitle1: {
      fontFamily: [
        'Parisienne',
      ].join(','),
      fontSize: "1.5rem",
    },
    h4: {
      fontFamily: [
        'Parisienne',
      ].join(','),
      fontSize: "2rem",
    },
    h3: {
      fontFamily: [
        'Parisienne',
      ].join(','),
      fontSize: "2.5rem",
    },
    subtitle2: {
      fontFamily: [
        'Parisienne',
      ].join(','),
      fontSize: "4.5rem",
    }
  },
});

//'Parisienne',

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
