import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import Covid from "./Covid";

const useStyles = makeStyles((theme) => ({}));

export default function CovidDialog(props) {
  const { handleClose, handleCovidAccept } = props;
  return (
    <>
      <DialogTitle>COVID-19 Announcement</DialogTitle>
      <DialogContent>
        <Covid />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleCovidAccept}>
          OK
        </Button>
      </DialogActions>
    </>
  );
}
