import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Grid, Button, Divider } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Rsvp from "./Rsvp";
import Image from "material-ui-image";
import ReactPlayer from "react-player";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@material-ui/lab";
import SchoolIcon from "@material-ui/icons/School";
import FlightTakeOffIcon from "@material-ui/icons/FlightTakeoff";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  main: {
    marginTop: "100px",
    marginBottom: "100px",
  },
  mainMobile: {
    marginTop: "25px",
    marginBottom: "50px",
  },
  btn: {
    marginTop: "24px",
    marginBottom: "12px",
  },
  divider: {
    width: "30%",
    margin: "auto",
    marginTop: "24px",
    marginBottom: "24px",
  },
  after: {
    marginBottom: "100px"
  },
  afterMobile: {
    marginBottom: "25px"
  }
}));

export default function About() {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
    <Grid container spacing={3} className={!isMobile && classes.main}>
      <Grid item sm={4} xs={12} className={isMobile && classes.mainMobile}>
        <Typography variant="h3">Who</Typography>
        <Divider variant="middle" className={classes.divider}/>
        <Typography variant="body1">Our close friends and family.</Typography>
        <Rsvp
          variant="outlined"
          color="primary"
          style={classes.btn}
        />
      </Grid>
      <Grid item sm={4} xs={12} className={isMobile && classes.mainMobile}>
        <Typography variant="h3">Where</Typography>
        <Divider variant="middle" className={classes.divider}/>
        <Typography variant="body1">Eustis Estate</Typography>
        <Typography variant="body1">
          1424 Canton Ave, Milton, MA 02186
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          href="https://www.google.com/maps/place/Eustis+Estate+Museum+and+Study+Center/@42.2327758,-71.1120393,17z/data=!3m1!4b1!4m5!3m4!1s0x89e37e6dfc8f41a9:0x53c5f68ac8055d7a!8m2!3d42.2327758!4d-71.1098506"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.btn}
        >
          See on map
        </Button>
      </Grid>
      <Grid item sm={4} xs={12} className={isMobile && classes.mainMobile}>
        <Typography variant="h3">When</Typography>
        <Divider variant="middle" className={classes.divider}/>
        <Typography variant="body1">
          Saturday, November 28, 2020
        </Typography>
        <Typography variant="body1">
          5-10PM
        </Typography>
        <br /> 
        <Typography variant="body1">
          The ceremony will start promply at 5:30PM.
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={3}>
      <Grid item xs={12} className={isMobile ? classes.afterMobile : classes.after}>
        <Divider variant="middle" />
      </Grid>
    </Grid>
    </>
  );
}
