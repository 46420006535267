import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { green } from "@material-ui/core/colors";
import CovidDialog from "./CovidDialog";
import MoodIcon from "@material-ui/icons/Mood";
const axios = require("axios");
//const admin = require('firebase-admin');
//admin.initializeApp();

//const db = admin.firestore();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  section: {
    marginBottom: "12px",
  },
  mainTitle: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  rsvpButton: {
    marginTop: "1rem",
    color: "white",
    border: "1px solid white",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      outline: theme.palette.primary.main,
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function FormDialog(props) {
  const { variant, color, style } = props;
  const classes = useStyles();
  const [rsvp, setRsvp] = React.useState({
    firstName: "",
    lastName: "",
    willAttend: null,
    meal: "",
    note: "",
    email: "",
  });
  const [valid, setValid] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const [acceptedCovid, setAcceptedCovid] = React.useState(false);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCovidAccept = () => {
    setAcceptedCovid(true);
  }

  const handleSubmit = async () => {
    if (!loading && !success) {
      setSuccess(false);
      setLoading(true);
      try {
        const guests = await axios.post(
          "https://us-central1-michaelplushelena.cloudfunctions.net/addGuest",
          rsvp
        );
        setSuccess(true);
        setLoading(false);
      } catch (error) {
        console.log(error);
        alert("Something went wrong");
      }
    } else {
      handleClose();
    }
  };

  const checkForValidRSVP = () => {
    if (rsvp.willAttend === null) {
      setValid(false);
    } else if (!rsvp.firstName) {
      setValid(false);
    } else if (!rsvp.lastName) {
      setValid(false);
    } else if (!rsvp.willAttend) {
      setValid(true);
    } else if (!rsvp.meal) {
      setValid(false);
    } else {
      setValid(true);
    }
  };

  const setAttendance = (event) => {
    if (event.target.name === "attending") {
      setRsvp({ ...rsvp, willAttend: true });
    } else {
      setRsvp({ ...rsvp, willAttend: false, meal: "" });
    }
  };

  const handleChange = (event) => {
    setRsvp({ ...rsvp, [event.target.name]: event.target.value });
  };

  React.useEffect(() => {
    //update form validity
    checkForValidRSVP();
  }, [rsvp]);

  React.useEffect(() => {
    if (!open) {
      setRsvp({
        firstName: "",
        lastName: "",
        willAttend: null,
        meal: "",
        note: "",
      });
      setLoading(false);
      setSuccess(null);
      setValid(false);
      setAcceptedCovid(false);
    }
  }, [open]);

  return (
    <div>
      <Button
        variant={variant}
        color={color}
        className={style}
        onClick={handleClickOpen}
      >
        RSVP
      </Button>
      <Dialog
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {acceptedCovid ? (
          <>
            <DialogTitle>RSVP</DialogTitle>
            <DialogContent>
              {success && (
                <DialogContentText>
                  {rsvp.willAttend
                    ? "Thanks for your RSVP, we are so excited to have you join us!"
                    : "We're sad that you won't be able to join, but thanks for letting us know."}
                </DialogContentText>
              )}
              {!success && (
                <>
                  <DialogContentText>
                    Please let us know if you will be able to join us in Milton,
                    MA on November 28 to celebrate our wedding!
                  </DialogContentText>
                  <Grid container spacing={3} className={classes.section}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        type="text"
                        fullWidth
                        value={rsvp.firstName}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        margin="dense"
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        type="text"
                        fullWidth
                        value={rsvp.lastName}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className={classes.section}>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={loading}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                            name="attending"
                            checked={
                              rsvp.willAttend !== null &&
                              rsvp.willAttend === true
                            }
                          />
                        }
                        onChange={setAttendance}
                        label="Yes, I will be attending!"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={loading}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxIcon />}
                            name="not_attending"
                            checked={
                              rsvp.willAttend !== null &&
                              rsvp.willAttend === false
                            }
                          />
                        }
                        onChange={setAttendance}
                        label="No, I will not be attending :("
                      />
                    </Grid>
                  </Grid>
                  {rsvp.willAttend && (
                    <>
                      <Grid container spacing={3} className={classes.section}>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            Please select your preferred entrée.
                          </Typography>
                          <RadioGroup
                            aria-label="meal"
                            name="meal"
                            value={rsvp.meal}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="steak"
                              control={<Radio />}
                              label="Steak"
                              disabled={loading}
                            />
                            <FormControlLabel
                              value="seafood"
                              control={<Radio />}
                              label="Seafood"
                              disabled={loading}
                            />
                            <FormControlLabel
                              value="vegetarian"
                              control={<Radio />}
                              label="Vegetarian"
                              disabled={loading}
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {rsvp.willAttend !== null && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="body1">
                          Leave a note for the bride and groom!
                        </Typography>
                        <TextField
                          id="note"
                          name="note"
                          label="Message"
                          multiline
                          fullWidth
                          rowsMax={10}
                          value={rsvp.note}
                          onChange={handleChange}
                          disabled={loading}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="email"
                          name="email"
                          label="Email (Optional)"
                          fullWidth
                          value={rsvp.email}
                          onChange={handleChange}
                          disabled={loading}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </DialogContent>
            <DialogActions>
              {!success && (
                <Button
                  onClick={handleClose}
                  color="primary"
                  disabled={loading}
                >
                  Cancel
                </Button>
              )}
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  className={buttonClassname}
                  disabled={!valid || loading}
                  onClick={handleSubmit}
                >
                  {success ? "Close" : "Submit"}
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </DialogActions>
          </>
        ) : (
          <CovidDialog handleClose={handleClose} handleCovidAccept={handleCovidAccept} />
        )}
      </Dialog>
    </div>
  );
}
