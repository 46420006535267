import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Grid, GridList, GridListTile } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Image from "material-ui-image";
import ReactPlayer from "react-player";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@material-ui/lab";
import SchoolIcon from "@material-ui/icons/School";
import FlightTakeOffIcon from "@material-ui/icons/FlightTakeoff";
import FavoriteIcon from "@material-ui/icons/Favorite";
import timeline from "./timeline";
timeline.sort((a, b) => (a.date < b.date ? -1 : 1));

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function CoupleTimeline() {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const items = timeline.map((item, index) => {
    let icon = <FlightTakeOffIcon />;
    switch (item.icon) {
      case "School":
        icon = <SchoolIcon />;
        break;

      default:
        // flight icon
        break;
    }
    let content;
    let src = "";
    switch (item.type) {
      case "video":
        src = "./photos/" + item.content;
        content = (
          <ReactPlayer
            url={src}
            playing={true}
            loop={true}
            muted={true}
            width="100%"
          />
        );
        break;
      case "photo":
        src = "./photos/" + item.content;
        let style = { width: "100%", height: "inherit" };
        if (item.orientation === "portrait") {
          style = { width: "inherit", height: "100%" };
        }
        content = <Image src={src} imageStyle={style} />;
        break;
      default:
        // "text"
        content = <Typography>{item.content}</Typography>;
        break;
    }
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary">{icon}</TimelineDot>
          {index !== timeline.length - 1 && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h6" component="h1">
              {item.date_group}&nbsp;&nbsp;|&nbsp;&nbsp;{item.location}
            </Typography>
            {content}
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  });

  const tiles = timeline.map((item, index) => {
    let content;
    let src = "";
    switch (item.type) {
      case "video":
        src = "./photos/" + item.content;
        content = (
          <ReactPlayer
            url={src}
            playing={true}
            loop={true}
            muted={true}
            width="100%"
          />
        );
        break;
      case "photo":
        src = "./photos/" + item.content;
        let style = { width: "100%", height: "inherit" };
        if (item.orientation === "portrait") {
          style = { width: "inherit", height: "100%" };
        }
        content = <Image src={src} imageStyle={style} />;
        break;
      default:
        // "text"
        content = <Typography>{item.content}</Typography>;
        break;
    };

    return (
      <GridListTile key={item.img} cols={item.orientation === "portrait" ? 1 : 2} rows={item.orientation === "portrait" ? 3 : 2} style={{margin: "auto", textAlign: "center"}}>
        {content}
      </GridListTile>
    )
  });

  return (
    <Grid container spacing={3} className={classes.formItems}>
      {/*
      <Grid item xs={12}>
        <Typography variant="h3">Our Story</Typography>
        <GridList cellHeight={160} className={classes.gridList} cols={6}>
          {tiles}
        </GridList>
      </Grid>
      */}
      <Grid item xs={12}>
        <Typography variant="h3">Our Story</Typography>

        <Timeline align={isMobile ? "left" : "alternate"}>{items}</Timeline>
      </Grid>
    </Grid>
  );
}
