import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Rsvp from "./Rsvp";
import Image from "material-ui-image";
import ReactPlayer from "react-player";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@material-ui/lab";
import SchoolIcon from "@material-ui/icons/School";
import FlightTakeOffIcon from "@material-ui/icons/FlightTakeoff";
const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  main: {
    marginTop: "100px",
    marginBottom: "100px",
  },
  mainMobile: {
    marginTop: "25px",
    marginBottom: "50px",
  },
  btn: {
    marginTop: "24px",
    marginBottom: "12px",
  },
  divider: {
    width: "30%",
    margin: "auto",
    marginTop: "24px",
    marginBottom: "24px",
  },
  after: {
    marginBottom: "100px",
  },
  afterMobile: {
    marginBottom: "25px",
  },
}));

export default function Guests() {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [guestList, setGuestList] = React.useState(null);
  const [fakePassword, setFakePassword] = React.useState('');

  React.useEffect(() => {
    async function fetchData() {
      const guests = await axios.post(
        "https://us-central1-michaelplushelena.cloudfunctions.net/getGuestList",
        ""
      );
      const list = guests.data.guestList;
      list.sort((a, b) => (a.lastName < b.lastName ? 1 : -1));
      list.sort((a, b) => (a.side > b.side ? 1 : -1));
      setGuestList(list);
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  const updatePW = event => {
    setFakePassword(event.target.value);
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {fakePassword === "letitsnow" ?
          <>
          <Typography variant="h3" style={{textAlign: "center"}}>Guest List</Typography>
          <TableContainer component={Paper} style={{maxWidth: "1000px", margin: "auto"}}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Attending</TableCell>
                  <TableCell>Meal</TableCell>
                  <TableCell>Note</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {guestList &&
                  guestList.map((row) => (
                    <TableRow key={row.firstName + row.lastName}>
                      <TableCell component="th" scope="row">
                        {row.firstName + " " + row.lastName}
                      </TableCell>
                      <TableCell>
                        {row.willAttend ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>
                        {row.willAttend ? row.meal : "N/A"}
                      </TableCell>
                      <TableCell>{row.note}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          </>
          :
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container alignItems="center">
              <TextField style={{margin: "auto", marginTop: "100px"}} id="outlined-basic" label="Password" variant="outlined" onChange={updatePW}/>
            </Grid>
            
          </form>
          }
          
        </Grid>
      </Grid>
    </>
  );
}
